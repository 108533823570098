import store from '../store'
export default {
	mounted(el, bindling) {
		let perVal = bindling.value;
		if (bindling.value) {
			let pers = store.state.btnlist
			let hasPer = pers.some(item => {
				return item == perVal
			});
			//没有权限就先隐藏此元素吧
			if (!hasPer) {
				el.style.display = "none"
			}
		}
	}
}
