import {
	API
} from '../config/index.js'
import router from '../router'
import axios from 'axios'
import NProgress from 'nprogress'
import store from '../store'
import md5 from "./../js/md5.js"
import {
	ElMessageBox,
	ElMessage
} from 'element-plus'

let baseURL = API
const request = axios.create({
	baseURL: baseURL,
	// timeout: 100 * 1000
})
// 设置session级别的本地存储 存储基本url 用于上传组件的url拼接 方便以后替换
sessionStorage.setItem('baseURL', baseURL)
request.interceptors.request.use(
	config => {
		//得到sign
		const getSign = (param, key) => {
			let str = "";
			for (var idx in param) {
				let one = idx + "=" + param[idx];
				if (str == "") {
					str = one;
				} else {
					str = str + "&" + one;
				}
			}
			str = str + "&key=" + key;
			let md5str = md5(str);
			return md5str;
		}
		
		// 获取签名参数
		let appid = "LyYiPZxckkSpJopHrl";
		let appkey = "KbrUeLTg5rDutdnUY1iThqJ";
		let timestamp = parseInt((new Date()).getTime() / 1000);
		let nonce = Math.floor(Math.random() * 8999) + 1000;
		
		let param = {
			appid: appid,
			nonce: nonce,
			timestamp: timestamp,
		}
		let sign = getSign(param, appkey);
		param.sign = sign;
		sessionStorage.setItem('param', JSON.stringify(param))
		
		
		if (sessionStorage.token) {
			config.headers['authorization'] = sessionStorage.token
		}
		// 签名
		config.headers['appid'] = param.appid
		config.headers['nonce'] = param.nonce
		config.headers['timestamp'] = param.timestamp
		config.headers['sign'] = param.sign
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

request.interceptors.response.use(

	response => {
		// 统计
		if (response.request.responseURL != baseURL + '/System/statistics') {
			NProgress.start()
		}
		if (response.data.code == 1) {
			NProgress.done() // 进度条结束
			return response.data
		} else if (response.data.code == 10001 || response.data.code == 401) {
			sessionStorage.removeItem('token')
			ElMessage.error('凭证失效，请重新登录')
			let getroute = []
			store.dispatch('GETNAVLIST', getroute)
			setTimeout(function() {
				router.push('/login')
			}, 200)
			NProgress.done() // 进度条结束
		} else {
			NProgress.done() // 进度条结束
			ElMessage.error(response.data.msg)
		}
	},
	error => {
		if (error.response.status === 401) {
			let getroute = []
			store.dispatch('GETNAVLIST', getroute)
			sessionStorage.removeItem('token')
			ElMessage.error('凭证失效，请重新登录')
		}
		if (error.response.status === 500) {
			let getroute = []
			store.dispatch('GETNAVLIST', getroute)
			sessionStorage.removeItem('token')
			ElMessage.error('凭证失效，请重新登录')
			setTimeout(function() {
				router.push('/login')
			}, 200)
		}
		if (error.response.status === 10001) {
			let getroute = []
			store.dispatch('GETNAVLIST', getroute)
			sessionStorage.removeItem('token')
			ElMessage.error('凭证失效，请重新登录')
			setTimeout(function() {
				router.push('/login')
			}, 200)
		}
		if (error.message === 'Network Error') {
			ElMessage.error('网络异常')
		}
		return Promise.reject(error)
	}
)

export default request
