<template>
	<div id="nav">
		<router-view />
	</div>
</template>
<script>
	export default {
		data() {
			return {

			}
		},

		methods: {

		}
	}
</script>
