import {
	createRouter,
	createWebHistory
} from 'vue-router'

const routes = [{
		path: "/",
		name: "index",
		component: () => import('@/pages/layout/index.vue'),
		children: [{
				path: "/",
				name: "数据统计",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/index/index.vue")
			},
			{
				path: "/work",
				name: "工单列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/work/index.vue")
			},
			{
				path: "/warning",
				name: "预警工单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/warning/index.vue")
			},

			{
				path: "/overdue",
				name: "超期工单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/overdue/index.vue")
			},
			{
				path: "/users",
				name: "账号列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/users/index.vue")
			},
			{
				path: "/role",
				name: "角色列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/role/index.vue")
			},
			{
				path: "/trial",
				name: "审核列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/trial/index.vue")
			},
			{
				path: "/county",
				name: "乡镇列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/county/index.vue")
			},
			{
				path: "/duty",
				name: "地区职位",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/duty/index.vue")
			},
			{
				path: "/bmduty",
				name: "部门职位",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/bmduty/index.vue")
			},
			{
				path: "/department",
				name: "单位列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/department/index.vue")
			},
			{
				path: "/supervision",
				name: "督查督办工单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/supervision/index.vue")
			},
			{
				path: "/ask",
				name: "问责工单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/ask/index.vue")
			},
			{
				path: "/erdepartment",
				name: "二级部门列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/erdepartment/index.vue")
			},
			{
				path: "/sandepartment",
				name: "三级部门列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/sandepartment/index.vue")
			},
			{
				path: "/sidepartment",
				name: "四级部门列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/sidepartment/index.vue")
			},
			{
				path: "/notice",
				name: "通知列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/notice/index.vue")
			},
			{
				path: "/banner",
				name: "轮播列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/banner/index.vue")
			},
			{
				path: "/ytype",
				name: "业务分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/ytype/index.vue")
			},
			{
				path: "/sqtype",
				name: "诉求分类列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/sqtype/index.vue")
			},
			{
				path: "/etype",
				name: "二级列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/etype/index.vue")
			},
			{
				path: "/stype",
				name: "三级列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/stype/index.vue")
			},
			{
				path: "/xtype",
				name: "四级列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/xtype/index.vue")
			},
			{
				path: "/wtype",
				name: "五级列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/wtype/index.vue")
			},
			{
				path: "/feedback",
				name: "反馈列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/feedback/index.vue")
			},
			{
				path: "/info",
				name: "用户信息",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/info/index.vue")
			},

			{
				path: "/retreat",
				name: "申请退单列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/retreat/index.vue")
			},
			{
				path: "/delay",
				name: "延期工单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/delay/index.vue")
			},
			{
				path: "/union",
				name: "联合工单",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/union/index.vue")
			},
			{
				path: "/town",
				name: "镇列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/town/index.vue")
			},
			{
				path: "/village",
				name: "村列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/village/index.vue")
			},
			{
				path: "/street",
				name: "街道列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/street/index.vue")
			},
			{
				path: "/adopt",
				name: "通过列表",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/adopt/index.vue")
			},
			{
				path: "/config",
				name: "配置参数",
				meta: {
					keepAlive: false, //此页面需要缓存
				},
				component: () => import("@/views/config/index/index.vue")
			},

		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	//  记录位置
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				top: 0
			}
		}
	},
	routes
})

export default router
