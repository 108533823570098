import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		navlist: JSON.parse(sessionStorage.getItem("NavList")) || [],
		menulist: JSON.parse(sessionStorage.getItem("MenuList")) || [],
		selectlist: JSON.parse(sessionStorage.getItem("selectlist")) || [],
		btnlist: JSON.parse(sessionStorage.getItem("btnlist")) || [],
		userinfo:JSON.parse(sessionStorage.getItem("UserInfo")) || {},
	},
	mutations: {},
	actions: {
		USERINFO(state, data) {
			sessionStorage.setItem("UserInfo", JSON.stringify(data))
			state.state.userinfo = JSON.parse(sessionStorage.getItem("UserInfo"))
		},
		GETNAVLIST(state, data) {
			sessionStorage.setItem("NavList", JSON.stringify(data))
			state.state.navlist = JSON.parse(sessionStorage.getItem("NavList"))
		},

		MENULIST(state, data) {
			sessionStorage.setItem("MenuList", JSON.stringify(data))
			state.state.menulist = JSON.parse(sessionStorage.getItem("MenuList"))
		},
		SELECTLIST(state, data) {
			sessionStorage.setItem("selectlist", JSON.stringify(data))
			state.state.selectlist = JSON.parse(sessionStorage.getItem("selectlist"))
		},
		BTNLIST(state, data) {
			sessionStorage.setItem("btnlist", JSON.stringify(data))
			state.state.btnlist = JSON.parse(sessionStorage.getItem("btnlist"))
		},
	},
	modules: {

	}
})
